import React from "react";

import { useVimeoPlayer } from "../../hooks";
import VimeoPlayer from "../Shared/VimeoPlayer";

const LiveStreamVimeoPlayer = ({ pageSlug, iframeTitle, videoUrl }) => {  
  const { iframeRef } = useVimeoPlayer(pageSlug)

  return (
    <VimeoPlayer
      ref={iframeRef}
      title={iframeTitle}
      videoUrl={videoUrl}
    />
  )
}

export default LiveStreamVimeoPlayer;