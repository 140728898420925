import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ProductDetails from "../../components/product-details"
import FixedVideo from "../../components/FixedVideo"

const Product = ({ pageContext: product }) => {
  return (
    <Layout>
      <SEO title="Product" description={product.title} />
      <ProductDetails product={product} />
      <FixedVideo livePageQuery={product} />
    </Layout>
  )
}

export default Product
