import React from "react"
import { Alert } from "reactstrap"

import { getPrice } from "../utils/product"
import { useThemeSettings } from "../hooks";

const FreeShipping = ({ product }) => {
  const data = useThemeSettings();

  const freeShipping = data.wpgraphql.themeGeneralSettings.ACFShippingSiteWide
  const free = freeShipping.amountForFreeShipping <= getPrice(product.price)

  return freeShipping && free ? (
    <Alert className="mb-3">Shipping for this product is free!</Alert>
  ) : (
    ""
  )
}

export default FreeShipping
