import React, { useState } from "react"
import { Button, Form, FormGroup, Label, Input, Badge } from "reactstrap"
import { Link } from "gatsby"
import styled from "styled-components"

import { useCart, useAddToCart } from "../hooks"
import CardModal from "./CardModal"
import ListSignup from "./ListSignup"
import { convertSlugToTitle } from "../utils/utilities"
import { ShoppingCart } from "styled-icons/fa-solid/ShoppingCart"

const CartWhite = styled(ShoppingCart)`
  color: #fff;
  width: 1rem;
`

const ProductDetailCartForm = ({ product }) => {
  const {
    name: productName,
    price,
    type,
    ACFEcommerce,
    variations: { nodes: variations } = {},
  } = product
  const { isProductExist } = useCart()
  const { addToCartHandler } = useAddToCart()
  const [qty, setQty] = useState(1)
  const [variationId, setVariationId] = useState()

  // const hasPrice = price && ACFEcommerce.ecommerceAndShipping === "ecommerce"
  // const freeGift = product.ACFEcommerce.freeGiftWithSignup === "freeGift"
  let hasPrice = price && ACFEcommerce.ecommerceAndShipping === "ecommerce"
  let variationLabelText
  if (type === "VARIABLE" && variations && variations.length) {
    hasPrice = true
    variationLabelText = convertSlugToTitle(
      variations[0].attributes.nodes[0].name
    )
  }

  const freeGift = product.ACFEcommerce.freeGiftWithSignup === "freeGift"

  const onSubmit = async e => {
    e.preventDefault()
    await addToCartHandler(product, qty, variationId)
  }

  const isProductAddedToCart = isProductExist(product)

  const onVariationChange = e => {
    setVariationId(e.target.value)
  }

  let variationProduct
  if (variationId) {
    variationProduct = variations.find(v => v.variationId == variationId)
  }

  const element = isProductAddedToCart && (
    <Link to="/cart" className="btn btn-cta text-center border-primary">
      View Cart{" "}
      <span>
        <CartWhite />
      </span>{" "}
    </Link>
  )

  return hasPrice && !freeGift ? (
    (!isProductAddedToCart && (
      <>
        {variations?.length && (
          <FormGroup style={{ display: "flex" }}>
            <Label htmlFor="variation" lg={3} style={{ paddingLeft: 0 }}>
              {variationLabelText}
            </Label>
            <Input
              type="select"
              onChange={onVariationChange}
              name="variation"
              id="variation"
            >
              <option value="">Choose an option</option>
              {variations.map(v => {
                return (
                  <option key={v.variationId} value={v.variationId}>
                    {v.attributes.nodes[0].value}
                  </option>
                )
              })}
            </Input>
          </FormGroup>
        )}
        {variationProduct && (
          <h5>
            <del>{variationProduct.regularPrice}</del>{" "}
            <span className="text-secondary">{variationProduct.salePrice}</span>
          </h5>
        )}
        <Form onSubmit={onSubmit} inline>
          <FormGroup>
            <Label htmlFor="quantity" className="mr-2">
              Quantity
            </Label>
            <Input
              style={{ width: "5rem" }}
              type="number"
              name="quantity"
              id="quantity"
              className="mr-2"
              value={qty}
              min={1}
              onChange={e => setQty(e.target.value)}
            />
          </FormGroup>{" "}
          <Button
            className="btn-cta"
            type="submit"
            disabled={variations?.length && !variationId}
          >
            Add to Cart
          </Button>
        </Form>
      </>
    )) ||
      element
  ) : freeGift ? (
    <ListSignup productName={productName} />
  ) : (
    <CardModal product={product} />
  )
}

export default ProductDetailCartForm
