import React from "react"
import { useLiveStream } from "../hooks"
import LiveStreamVimeoPlayer from "./LiveStreamVimeoPlayer"
import FacebookPlayer from "./Shared/FacebookPlayer"

const FixedVidoInner = ({ livePageQuery, liveStreamFields }) => {
  const isFacebook = liveStreamFields?.videoUrl?.indexOf("facebook") > -1

  return (
    <div class="fixed-video">
      {(isFacebook && (
        <FacebookPlayer
          iframeTitle={livePageQuery.title}
          videoUrl={liveStreamFields?.videoUrl}
        />
      )) || (
        <LiveStreamVimeoPlayer
          pageSlug={livePageQuery.slug}
          iframeTitle={livePageQuery.title}
          videoUrl={liveStreamFields?.videoUrl}
        />
      )}
    </div>
  )
}

const FixedVideo = ({ livePageQuery }) => {
  const {
    loading: isStreamFieldsLoading,
    fields: liveStreamFields,
  } = useLiveStream()

  return (
    (!isStreamFieldsLoading && liveStreamFields.liveOn && (
      <FixedVidoInner
        liveStreamFields={liveStreamFields}
        livePageQuery={livePageQuery}
      />
    )) || <div />
  )
}

export default FixedVideo
