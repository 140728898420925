import React from "react"

const Discount = ({ product }) => {
  if (
    product.ACFEcommerce.ecommerceAndShipping === "ecommerce" &&
    product.onSale &&
    product.regularPrice &&
    product.salePrice
  ) {
    if (typeof window !== "undefined") {
      var regularPriceNumber = Number(
        product.regularPrice.replace(/[^0-9.]/g, "")
      )
    }

    if (typeof window !== "undefined") {
      var salePriceNumber = Number(product.salePrice.replace(/[^0-9.]/g, ""))
    }
    var discount = Math.floor(
      100 - (salePriceNumber / regularPriceNumber) * 100
    )

    const savingAmount = Math.round(
      (regularPriceNumber * 100 - salePriceNumber * 100) / 100,
      2
    )

    return (
      <p>
        You save ${savingAmount} ( {discount}% ).
      </p>
    )
  } else {
    return null
  }
}

export default Discount
