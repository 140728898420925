const toNumber = input => {
  let rVal
  if (typeof input !== "number") {
    rVal = Number(input.replace(/[^0-9.]/g, ""))
  } else {
    rVal = input
  }

  return rVal
}

const convertSlugToTitle = (slug) => {
  const s = slug.split("-");

  return s.reduce((acc, curr) => {
    return acc + ` ${curr[0].toUpperCase()}${curr.slice(1)}`;
  }, [])
}

module.exports = {
  toNumber,
  convertSlugToTitle
}

  