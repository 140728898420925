import React from "react"

const ProductPrice = ({ product }) => {
  const ecommerce = product.ACFEcommerce.ecommerceAndShipping

  if (ecommerce === "ecommerce" && product.price && !product.onSale) {
    return <h5>{product.price}</h5>
  } else if (
    product.ACFEcommerce.ecommerceAndShipping === "ecommerce" &&
    product.onSale &&
    product.regularPrice &&
    product.salePrice
  ) {
    return (
      <h5>
        <>
          <del>{product.regularPrice}</del>{" "}
        </>
        <span className="text-secondary">{product.salePrice}</span>
      </h5>
    )
  } else {
    return null
  }
}

export default ProductPrice
