import React from "react"

const VimeoPlayer = React.forwardRef((props, ref) => {
  const { title, videoUrl, ...otherProps } = props

  return (
    <div className="player-wrapper mb-3">
      <iframe
        ref={ref}
        title={title}
        src={videoUrl}
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
        {...otherProps}
      />
    </div>
  )
})

export default VimeoPlayer
