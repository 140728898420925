import React from "react";

const FacebookPlayer = ({ iframeTitle, videoUrl, ...otherProps }) => {
  return ( 
    <div className="player-wrapper mb-3">
      <iframe
        title={iframeTitle}
        src={videoUrl}
        scrolling="no"
        frameborder="0"
        allowTransparency="true" 
        allowFullScreen="true" 
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
        {...otherProps}
      />
    </div>
  )
}

export default FacebookPlayer;