import React from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap"

import ProductPrice from "./ProductPrice"
import ProductDetailCartForm from "./ProductDetailCartForm"
import Discount from "./Discount"
import FreeShipping from "./FreeShipping"
// import Financing from "./Financing"

// import MyGallery from "./ProductGallery"

import ImageGallery from "react-image-gallery"
import "react-image-gallery/styles/css/image-gallery.css"

const ProductDetails = ({ product }) => {
  const gallery = product?.galleryImages?.edges
  const errorImage = product?.image?.sourceUrl
  const defaultImage = [
    {
      original: product?.image?.sourceUrl,
      sourceSet: product?.image?.srcSet,
      thumbnail: product?.image?.sourceUrl,
      originalAlt: product?.image?.altText,
    },
  ]

  const galleryImages = gallery.map(image => ({
    original: image?.node?.sourceUrl,
    sourceSet: image?.node?.srcSet,
    thumbnail: image?.node?.sourceUrl,
    originalAlt: image?.node?.altText,
  }))

  const images = defaultImage?.concat(galleryImages)

  return (
    <Container className="pt-5 px-sm-4">
      <Row className="mb-3">
        <Col>
          <h1 dangerouslySetInnerHTML={{ __html: product?.name }} />
        </Col>
      </Row>
      <Row className="mb-5">
        <Col lg="6">
          <Card>
            <ImageGallery
              items={images}
              lazyLoad={true}
              onErrorImageURL={errorImage}
            />
          </Card>
        </Col>
        <Col lg="6">
          <p dangerouslySetInnerHTML={{ __html: product?.shortDescription }} />
          <ProductPrice product={product} />
          {/* <Financing product={product} /> */}
          <FreeShipping product={product} />

          <Discount product={product} />
          <ProductDetailCartForm product={product} />
        </Col>
      </Row>

      <Row>
        <Col md="12">
          <Card>
            <CardBody>
              <div dangerouslySetInnerHTML={{ __html: product?.description }} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default ProductDetails
